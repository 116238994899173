.App {
  text-align: center;
  /* width: 100vw;
  height: 100vh; */
}

/* body {
  margin: 0;
  padding: 0;
} */

/* .Sidebar {
  height: 100%;
  width: 250px;
  background-color: #2F4050;
} */

.SidebarList {
  height: auto;
  padding: 0;
  width: 100%;
}

.SidebarList .row {
  width: 100%;
  height: 40px;
  list-style-type: none;
  padding-left: 15;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.SidebarList .row:hover {
  cursor: pointer;
  background-color: #5E8F72;
}

.SidebarList .row#active {
  
  background-color:#8db1a1;
 
}

.row #icon {
  flex: 100%;
  display: flex;
  color: #2A6049;
  place-items: center;
  font-size: 35px;
}

.row #title {
  flex: 70%;
  margin-left: 15px;
  color: #2A6049;
}